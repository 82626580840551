@keyframes fadeInOut {
    0% {
        opacity: 0;
        display: flex;
    }
    10%, 23% {
        opacity: 1;
        display: flex;
    }
    33%, 100% {
        opacity: 0;
        display: none;
    }
}

@keyframes fadeInOut2 {
    0%, 33% {
        opacity: 0;
        display: none;
    }
    43%, 56% {
        opacity: 1;
        display: flex;
    }
    66%, 100% {
        opacity: 0;
        display: none;
    }
}

@keyframes fadeInOut3 {
    0%, 66% {
        opacity: 0;
        display: none;
    }
    76%, 89% {
        opacity: 1;
        display: flex;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
